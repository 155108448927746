import md5 from 'js-md5'//签名

export const makeSign = (inObject) => {
    const sortedParam = sorter(inObject);
    let needSignatureStr = "";
    let keyArr = Object.keys(sortedParam)
    for (const key in sortedParam) {
        const value = sortedParam[key];
        // if (value === null || value === undefined || value === 'undefined') {
        //     continue
        // }
        if (value === null || value === undefined) {
            continue
        }
        needSignatureStr = `${needSignatureStr}${key}=${sortedParam[key]}`
        if (key !== keyArr[keyArr.length - 1]) {
            needSignatureStr = `${needSignatureStr}&`
        }
    }
    const key = process.env.VUE_APP_SIGN_KEY;
    return md5(needSignatureStr + key);
}


const sorter = (inObject) => {
    const sortedJson = {};
    const sortedKeys = Object.keys(inObject).sort();
    for (let i = 0; i < sortedKeys.length; i++) {
        sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
    }
    return sortedJson;
}
export const getTime = () => {
    let date = new Date();   //将时间格式转为字符串
    return Date.parse(date.toString()) / 1000
}

